import { Checkbox, FormControl, FormControlLabel, MenuItem, Select } from '@mui/material';
import DatePicker from 'react-datepicker';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CheckIcon from '../../Assets/Images/icon-checkbox-checked.png';
import UncheckIcon from '../../Assets/Images/icon-checkbox-unchecked.png';
import Popup from '../../Commons/Components/Popup';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import './index.scss';

const Term = ({ title, message }) => (
  <div className="enrolment_term">
    <h1 id="enrolment_term-message-receive-title" className="page-title">
      {title}
    </h1>
    <br />
    <br />
    <div id="enrolment_term-message-receive-instruction" className="page-body">
      <p id="enrolment_term-message-receive-instruction-paragraph" dangerouslySetInnerHTML={{ __html: message }}></p>
    </div>
  </div>
);

const View = ({
  isLoading,
  lecture,
  onClickToggleAgreementTerms,
  agreementTerms,
  onClickPayment,
  formatPhoneNumber,
  thirdPartyQuestionFieldList,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const userInfo = useSelector((state) => state.user.userInfo) || {};
  const [useUserInfo, setUseUserInfo] = useState(false);
  const [selectedEducationValue, setSelectedEducationValue] = useState('');
  const thirdPartyQuestionFields = useMemo(
    () => (lecture ? lecture.ThirdPartyClassQuestions.map((question) => question.question_field) : []),
    [lecture],
  );
  const questionFieldList = useMemo(
    () => (thirdPartyQuestionFieldList ? Object.values(thirdPartyQuestionFieldList) : []),
    [thirdPartyQuestionFieldList],
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
    control,
    watch,
  } = useForm({
    defaultValues: {},
    mode: 'all', // 입력 변경시 유효성 검사를 수행
  });

  const handleChangeEducation = (event) => {
    setSelectedEducationValue(event.target.value);
  };

  const getValidationMsg = () => {
    if (errors?.user_name) {
      return '* 이름을 정확히 입력해주세요.';
    } else if (errors?.user_phone) {
      return '* 연락처를 정확히 입력해주세요';
    } else if (errors?.user_email) {
      return '* 이메일 주소를 정확히 입력해주세요';
    } else if (errors?.user_birth) {
      return '* 생년월일을 정확히 입력해주세요';
    } else if (errors?.user_graduation_year) {
      return '* 졸업년도를 정확히 입력해주세요';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!isLoading && lecture) {
      setValue('lecture_title', lecture.title);
    }
  }, [isLoading]);

  useEffect(() => setValue('agreement', agreementTerms), [agreementTerms]);
  useEffect(() => {
    if (useUserInfo) {
      setValue('user_name', userInfo.name, { shouldValidate: true });
      setValue('user_phone', formatPhoneNumber(userInfo.phone), { shouldValidate: true });
      setValue('user_email', userInfo.email, { shouldValidate: true });
    } else {
      setValue('user_name', '', { shouldValidate: true });
      setValue('user_phone', '', { shouldValidate: true });
      setValue('user_email', '', { shouldValidate: true });
    }
  }, [useUserInfo]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="enrolment_wrap">
      <div className="inner_wrap">
        <div className="header">
          <div className="enrolment_form-title">교육신청</div>
        </div>
        <div className="content-wrap">
          <div className="enrolment_loggedInUserInfo_wrap">
            <h3>회원정보</h3>
            <ul className="form-fields-wrap">
              <li>
                <label>이름</label>
                <input type="text" defaultValue={userInfo.name} readOnly={true} tabIndex={-1} />
              </li>
              <li>
                <label>연락처</label>
                <input
                  type="tel"
                  defaultValue={userInfo.phone && formatPhoneNumber(userInfo.phone)}
                  readOnly={true}
                  tabIndex={-1}
                />
              </li>
              <li>
                <label>이메일</label>
                <input type="email" defaultValue={userInfo.email} readOnly={true} tabIndex={-1} />
              </li>
            </ul>
          </div>
          <div className="enrolment_form_wrap">
            <div className="enrolment_form_header">
              <div className="title_wrap">
                <h3>신청정보</h3>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      disableRipple
                      sx={{ padding: 0 }}
                      onClick={(e) => setUseUserInfo(e.target?.checked)}
                    />
                  }
                  label="회원정보와 동일"
                  sx={{
                    fontSize: '0.75rem',
                    color: 'darkgray',
                    marginLeft: 1,
                  }}
                />
              </div>
              <div className="message_wrap">
                * 수강 안내 및 교육이수증 발급에 필요한 정보이니 모든 항목을 정확하게 입력해 주세요.
              </div>
            </div>

            <ul className="form-fields-wrap">
              <li>
                <label>강의명</label>
                <input
                  type="text"
                  readOnly
                  {...register('lecture_title', {
                    required: true,
                  })}
                />
              </li>

              {thirdPartyQuestionFields.find((field) => field === 'user_name') && (
                <li className={isMobile && errors.user_name ? 'error-list' : ''}>
                  <label htmlFor="user_name">
                    {questionFieldList.find((field) => field.field === 'user_name').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_name').is_optional && '*'}
                  </label>
                  <div className="form-fields-content-wrap">
                    <input
                      id="user_name"
                      type="text"
                      placeholder="본인의 이름을 작성해 주세요."
                      {...register('user_name', {
                        required: !questionFieldList.find((field) => field.field === 'user_name').is_optional,
                        pattern: /^[가-힣a-zA-Z]{2,}$/,
                        value: null,
                      })}
                      readOnly={useUserInfo}
                    />
                    {!isMobile && (
                      <span className="form-fields--error fields--error-comment">
                        {errors.user_name ? '* 이름을 정확히 입력해주세요' : ''}
                      </span>
                    )}
                  </div>
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_phone') && (
                <li className={isMobile && errors.user_phone ? 'error-list' : ''}>
                  <label htmlFor="user_phone">
                    {questionFieldList.find((field) => field.field === 'user_phone').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_phone').is_optional && '*'}
                  </label>
                  <div className="form-fields-content-wrap">
                    <input
                      id="user_phone"
                      type="tel"
                      placeholder="'-' 없이 숫자만 입력해 주세요."
                      {...register('user_phone', {
                        required: !questionFieldList.find((field) => field.field === 'user_phone').is_optional,
                        pattern: /^[0-9]{2,3}-?[0-9]{3,4}-?[0-9]{4}$/i,
                        value: null,
                      })}
                      readOnly={useUserInfo}
                      onBlur={(e) => {
                        setValue('user_phone', formatPhoneNumber(e.target.value), { shouldValidate: true });
                      }}
                    />
                    {!isMobile && (
                      <span className="form-fields--error fields--error-comment">
                        {errors.user_phone ? '* 연락처를 정확히 입력해주세요' : ''}
                      </span>
                    )}
                  </div>
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_email') && (
                <li className={isMobile && errors.user_email ? 'error-list' : ''}>
                  <label htmlFor="user_email">
                    {questionFieldList.find((field) => field.field === 'user_email').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_email').is_optional && '*'}
                  </label>
                  <div className="form-fields-content-wrap">
                    <input
                      id="user_email"
                      type="text"
                      placeholder="예) enterjobedu@k-lab.me"
                      {...register('user_email', {
                        required: !questionFieldList.find((field) => field.field === 'user_email').is_optional,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        value: null,
                      })}
                      readOnly={useUserInfo}
                    />
                    {!isMobile && (
                      <span className="form-fields--error fields--error-comment">
                        {errors.user_email ? '* 이메일 주소를 정확히 입력해주세요' : ''}
                      </span>
                    )}
                  </div>
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_birth') && (
                <li className={isMobile && errors.user_birth ? 'error-list' : ''}>
                  <label htmlFor="user_birth">
                    {questionFieldList.find((field) => field.field === 'user_birth').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_birth').is_optional && '*'}
                  </label>
                  <div className="form-fields-content-wrap">
                    <input
                      id="user_birth"
                      type="date"
                      min="1900-01-01"
                      max={new Date().toISOString().slice(0, 10)}
                      {...register('user_birth', {
                        required: !questionFieldList.find((field) => field.field === 'user_birth').is_optional,
                        pattern: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i,
                        value: null,
                      })}
                    />
                    {!isMobile && (
                      <span className="form-fields--error fields--error-comment">
                        {errors.user_birth ? '* 생년월일을 정확히 입력해주세요' : ''}
                      </span>
                    )}
                  </div>
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_gender') && (
                <li style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="user_gender" style={{ marginRight: '1rem' }}>
                    {questionFieldList.find((field) => field.field === 'user_gender').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_gender').is_optional && '*'}
                  </label>
                  <FormControl sx={{ minWidth: 120 }}>
                    <Select
                      {...register('user_gender', {
                        required: !questionFieldList.find((field) => field.field === 'user_gender').is_optional,
                        value: null,
                      })}
                      displayEmpty={true}
                      defaultValue=""
                      sx={{ fontSize: '0.875rem', height: '1.7rem' }}
                    >
                      <MenuItem value="" disabled sx={{ fontSize: '0.9rem' }}>
                        성별 선택
                      </MenuItem>
                      <MenuItem value="M" sx={{ fontSize: '0.9rem' }}>
                        남
                      </MenuItem>
                      <MenuItem value="F" sx={{ fontSize: '0.9rem' }}>
                        여
                      </MenuItem>
                    </Select>
                  </FormControl>
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_education') && (
                <li style={{ display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="user_education" style={{ marginRight: '1rem' }}>
                    {questionFieldList.find((field) => field.field === 'user_education').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_education').is_optional && '*'}
                  </label>
                  <FormControl sx={{ minWidth: 120 }}>
                    <Select
                      {...register('user_education', {
                        required: !questionFieldList.find((field) => field.field === 'user_education').is_optional,
                        value: null,
                      })}
                      onChange={handleChangeEducation}
                      displayEmpty={true}
                      defaultValue=""
                      sx={{ fontSize: '0.875rem', height: '1.7rem' }}
                    >
                      <MenuItem value="" disabled sx={{ fontSize: '0.9rem' }}>
                        최종학력 선택
                      </MenuItem>
                      <MenuItem value="고등학교 졸업" sx={{ fontSize: '0.9rem' }}>
                        고등학교 졸업
                      </MenuItem>
                      <MenuItem value="대학교 재학" sx={{ fontSize: '0.9rem' }}>
                        대학교 재학
                      </MenuItem>
                      <MenuItem value="대학교 졸업" sx={{ fontSize: '0.9rem' }}>
                        대학교 졸업
                      </MenuItem>
                      <MenuItem value="대학원 재학" sx={{ fontSize: '0.9rem' }}>
                        대학원 재학
                      </MenuItem>
                      <MenuItem value="대학원 졸업" sx={{ fontSize: '0.9rem' }}>
                        대학원 졸업
                      </MenuItem>
                      <MenuItem value="etc" sx={{ fontSize: '0.9rem' }}>
                        기타
                      </MenuItem>
                    </Select>
                  </FormControl>
                </li>
              )}

              {selectedEducationValue === 'etc' && (
                <li className="user_education_etc__list">
                  <label htmlFor="user_education">
                    <span>
                      <KeyboardReturnIcon
                        style={{ transform: 'scaleX(-1)', fontSize: '1rem', marginRight: '0.1rem' }}
                      />{' '}
                      기타 답변
                    </span>
                  </label>
                  <input {...register('user_education')} type="text" id="user_education" name="user_education" />
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_graduation_year') && (
                <li className="user_graduation_year__list">
                  <label htmlFor="user_graduation_year">
                    {questionFieldList.find((field) => field.field === 'user_graduation_year').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_graduation_year').is_optional && '*'}
                  </label>
                  <div className="form-fields-content-wrap">
                    <DatePicker
                      {...register('user_graduation_year', {
                        required: !questionFieldList.find((field) => field.field === 'user_graduation_year')
                          .is_optional,
                        value: null,
                      })}
                      popperPlacement="bottom"
                      dateFormat="yyyy"
                      showYearPicker
                      selected={watch('user_graduation_year')}
                      onChange={(date) => setValue('user_graduation_year', date)}
                      placeholderText="연도 선택"
                      maxDate={new Date(new Date().getFullYear() + 7, 11, 31)}
                      yearItemNumber={8}
                    />
                    {!isMobile && (
                      <span className="form-fields--error fields--error-comment">
                        {errors.user_graduation_year ? '* 졸업년도를 정확히 입력해주세요' : ''}
                      </span>
                    )}
                  </div>
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_school_name') && (
                <li>
                  <label htmlFor="user_school_name">
                    {questionFieldList.find((field) => field.field === 'user_school_name').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_school_name').is_optional && '*'}
                  </label>
                  <input
                    {...register('user_school_name', {
                      required: !questionFieldList.find((field) => field.field === 'user_school_name').is_optional,
                      value: null,
                    })}
                    type="text"
                    id="user_school_name"
                    name="user_school_name"
                  />
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_school_department') && (
                <li>
                  <label htmlFor="user_school_department">
                    {questionFieldList.find((field) => field.field === 'user_school_department').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_school_department').is_optional && '*'}
                  </label>
                  <input
                    {...register('user_school_department', {
                      required: !questionFieldList.find((field) => field.field === 'user_school_department')
                        .is_optional,
                      value: null,
                    })}
                    type="text"
                    id="user_school_department"
                    name="user_school_department"
                  />
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_apply_reason') && (
                <li className="user_apply_reason__list">
                  <label htmlFor="user_apply_reason">
                    {questionFieldList.find((field) => field.field === 'user_apply_reason').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_apply_reason').is_optional && '*'}
                  </label>
                  <textarea
                    {...register('user_apply_reason', {
                      required: !questionFieldList.find((field) => field.field === 'user_apply_reason').is_optional,
                      value: null,
                    })}
                    id="user_apply_reason"
                    name="user_apply_reason"
                    rows="5"
                  />
                </li>
              )}

              {thirdPartyQuestionFields.find((field) => field === 'user_recommender') && (
                <li>
                  <label htmlFor="user_recommender">
                    {questionFieldList.find((field) => field.field === 'user_recommender').label}{' '}
                    {!questionFieldList.find((field) => field.field === 'user_recommender').is_optional && '*'}
                  </label>
                  <input
                    {...register('user_recommender', {
                      required: !questionFieldList.find((field) => field.field === 'user_recommender').is_optional,
                      value: null,
                    })}
                    type="text"
                    id="user_recommender"
                    name="user_recommender"
                  />
                </li>
              )}
            </ul>

            {isMobile && Object.keys(errors).length > 0 && (
              <div>
                <span className="fields--error-comment">{getValidationMsg()}</span>
              </div>
            )}
          </div>

          <div className="enrolment_terms_box">
            <div className="enrolment_terms">
              <div className="agreement" onClick={onClickToggleAgreementTerms}>
                <span className="checkbox">
                  <img src={agreementTerms ? CheckIcon : UncheckIcon} alt="enrolment_agree_terms" />
                </span>
                <h3>교육정보 확인 및 개인정보 제공 동의</h3>
              </div>
              <ul className="terms_list_box">
                {lecture.ClassTerms.map((term) => (
                  <li key={term.id}>
                    {term.title}
                    <Popup
                      modal
                      trigger={
                        <span>
                          <FormattedMessage id="COMMON_READ_MORE" />
                        </span>
                      }
                    >
                      <Term title={term.title} message={term.content} />
                    </Popup>
                  </li>
                ))}
              </ul>
            </div>

            <input
              type="submit"
              id="enrolment_complete_click"
              className="enrolment_btn"
              onClick={(e) => {
                onClickPayment(getValues());
              }}
              disabled={!agreementTerms || !isValid}
              value="신청하기"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
